<template>
    <div>
        <div :class="['btn', { small: small }, { disabled: disabled }]" v-if="!submit && !link">
            <font-awesome-icon :icon="icon" v-if="icon" />
            <slot></slot>
        </div>

        <button type="submit" :class="['btn', { small: small }]" v-if="submit" :disabled="disabled">
            <font-awesome-icon :icon="icon" v-if="icon" />
            <slot></slot>
        </button>

        <router-link
            :to="link"
            :class="['btn', { small: small }, { disabled: disabled }]"
            v-if="link"
            :disabled="disabled"
        >
            <font-awesome-icon :icon="icon" v-if="icon" />
            <slot></slot>
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'Button',

    props: {
        link: { type: String, default: '' },
        submit: { type: Boolean, default: false },
        icon: { type: String, default: '' },
        small: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
    },
}
</script>

<style lang="scss">
.btn {
    background-image: linear-gradient(45deg, #023a51 0%, #0a526e 100%);
    display: inline-flex;
    align-items: center;
    height: 46px;
    padding: 10px 24px;
    border: 0;
    border-radius: 8px;
    text-decoration: none;
    text-align: left;
    color: $color-white;
    font-size: rem(14px);
    font-weight: $normal;
    position: relative;
    outline: none;
    cursor: pointer;
    z-index: 1;

    &:disabled,
    &.disabled {
        opacity: 0.3;
        cursor: not-allowed;
    }

    &.small {
        height: 36px;
        padding: 5px 7px;
    }

    .svg-inline--fa {
        margin-right: 12px;
    }

    &:before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(45deg, #023a51 0%, #023a51 100%);
        z-index: -1;
        transition: opacity 0.3s;
        opacity: 0;
        border-radius: 6px;
    }

    &:hover:before {
        opacity: 1;
        transition: opacity 0.3s;
    }

    &.outline {
        background-image: none;
        background: transparent;
        border: 1px solid $color-white;

        &:before {
            display: none;
        }

        &.error {
            border-color: $color-error;
            color: $color-error;

            &:hover {
                color: darken($color-error, 10%);
                color: darken($color-error, 10%);
            }
        }
    }
}
</style>
