<template>
    <div class="add-company">
        <!-- <Modal>{{ modalMessage }}</Modal> -->

        <div class="page__header">
            <h1>Bedrijven overzicht</h1>
            <div>
                <Input
                    v-model="searchModel"
                    v-on:keyup.backspace="filterBedrijven(searchModel, $event)"
                    type="text"
                    placeholder="Bedrijf zoeken"
                    :error="error"
                />
                <Button link="bedrijf-toevoegen" icon="building">Bedrijf toevoegen</Button>
            </div>
        </div>

        <hr class="seperator" />

        <div class="container">
            <Table
                :headings="[
                    { key: 'bedrijfsnaam', label: 'Bedrijfsnaam' },
                    { key: 'locaties', label: 'Locaties' },
                ]"
                :data="bedrijven"
                :showArrayCount="true"
                :hoverLocations="true"
                :withUtils="true"
                :locationButton="true"
                defaultValue="---"
            >
            </Table>
        </div>
    </div>
</template>

<script>
import Modal from '@/components/Modal.vue'
import Input from '@/components/inputs/Textfield.vue'
import Button from '@/components/Button.vue'
import Table from '@/components/Table.vue'
import { api } from '@/api'

export default {
    name: 'Bedrijfs-overzicht',

    components: {
        Modal,
        Input,
        Button,
        Table,
    },

    data() {
        return {
            searchModel: '',
            error: '',
            bedrijven: [],
            searchBackup: [],
        }
    },

    mounted() {
        this.fetchCompanies()
    },

    methods: {
        async fetchCompanies() {
            this.error = ''

            try {
                const response = await api.get('/fetchCompanies', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                        Permission: localStorage.getItem('permission'),
                    },
                })

                const result = await response.data.companies

                this.bedrijven = result

                this.searchBackup = this.bedrijven
            } catch (error) {
                return (this.error = 'Er zijn geen bedrijven gevonden in de database...')
            }
        },

        filterBedrijven(str, backspace) {
            if (!str || (backspace && str.length <= 3)) return (this.bedrijven = this.searchBackup)

            const matches = this.bedrijven.filter(x => {
                if (x.bedrijfsnaam.toLowerCase().indexOf(str) !== -1) {
                    return true
                }
            })

            this.bedrijven = matches
        },
    },

    watch: {
        searchModel(e) {
            this.filterBedrijven(e, false)
        },
    },
}
</script>

<style lang="scss">
.add-company {
    .container {
        background: $color-white;
        width: 100%;
        height: 100%;
        border-radius: 12px;
        padding: 24px;
        box-shadow: 0px 0px 10px rgba($color-maroon, 0.2);

        .input__rows {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 24px;
        }
    }

    hr {
        margin: 48px auto;
    }
}

h1,
h2 {
    font-size: rem(24px);
    font-weight: $semi-bold;
    color: $color-burgundy;
    margin: 0 0 24px;
}
</style>
