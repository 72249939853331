<template>
    <div>
        <div class="pmt__datatable" :style="{ '--columns': headings.length }">
            <template v-if="data.length">
                <div :class="['pmt__datatable__head', 'datatable__columns', { with__utils: withUtils }]">
                    <div v-for="{ label } in headings" :key="label">{{ label }}</div>
                    <div v-if="withUtils">Opties</div>
                </div>

                <div
                    :class="[
                        'pmt__datatable__row',
                        'datatable__columns',
                        { with__utils: withUtils },
                        { uitslag: item.uitslag == 0 || item.uitslag == 1 || item.uitslag == 2 },
                    ]"
                    v-for="(item, i) in data"
                    :key="i"
                    @click="route ? goTo(`locatie-toevoegen/${item.ID}`) : ''"
                >
                    <template v-for="{ label, key } in headings">
                        <div v-if="typeof item[key] == 'string'" :key="label">
                            {{ item[key] }}
                        </div>

                        <div v-if="typeof item[key] == 'object'">
                            <ul v-if="!hoverLocations">
                                <li v-if="showArrayCount">
                                    <strong>{{ item[key].length }}</strong>
                                </li>

                                <li v-for="(value, i) in item[key]" :key="i">
                                    {{ value }}
                                </li>
                            </ul>

                            <ul v-else>
                                <li v-if="showArrayCount">
                                    <strong>{{ item[key].length }}</strong>
                                </li>
                                <li v-for="(value, i) in item[key]" :key="i">
                                    <router-link :to="`/locatie/${item.ID}/${value}`">{{ value }}</router-link>
                                </li>
                            </ul>
                        </div>
                    </template>

                    <div v-if="withUtils">
                        <slot name="utils" v-if="locationButton">
                            <Button :link="`/locatie-toevoegen/${item.ID}`" :small="true">Locatie toevoegen</Button>
                        </slot>
                        <slot name="utils" v-else></slot>
                    </div>

                    <font-awesome-icon icon="chevron-right" v-if="hoverIcon" />
                </div>
            </template>

            <div v-else class="pmt__datatable__empty-message">
                <font-awesome-icon icon="exclamation-circle" />
                {{ emptyMessage }}
            </div>
        </div>
    </div>
</template>

<script>
import Button from '@/components/Button.vue'
import _ from 'lodash'

export default {
    name: 'Table',

    components: {
        Button,
    },

    props: {
        emptyMessage: { type: String, default: 'Geen data gevonden..' },
        headings: { type: Array, default: [{ key: 'foo', heading: 'Foo' }] },
        data: { type: Array, default: [{ foo: 'bar' }] },
        withUtils: { type: Boolean, default: false },
        defaultValue: { type: String, default: '---' },
        showArrayCount: { type: Boolean, default: false },
        hoverIcon: { type: Boolean, default: false },
        route: { type: Boolean, default: false },
        hoverLocations: { type: Boolean, default: false },
        locationButton: { type: Boolean, default: false },
    },

    methods: {
        goTo(location) {
            this.$router.push(location).catch(() => {})
        },
    },
}
</script>

<style lang="scss">
.pmt__datatable {
    --columns: none;
    --width: auto;

    margin: -#{$spacer};
    background: $color-white;
    text-align: left;

    .datatable__columns {
        display: grid;
        grid-template-columns: repeat(var(--columns), 1fr);
        grid-gap: 12px;
        align-items: center;

        &.with__utils {
            grid-template-columns: repeat(var(--columns), 1fr) minmax(150px, auto);
        }
    }

    &__empty-message {
        background: $color-echoblue;
        padding: $spacer;
        display: flex;
        align-items: center;
        color: $color-maroon;
        border-radius: 8px;

        .svg-inline--fa {
            margin-right: 12px;
        }
    }

    &__head {
        padding: $spacer;
        color: $color-maroon;
        font-weight: $bold;
    }

    &__row {
        background: $color-white;
        padding: $spacer;
        border-radius: 8px;
        position: relative;

        &:hover {
            background: $color-cosmiclatte;
            cursor: pointer;

            .fa-chevron-right.fa-w-10 {
                display: block;
            }
        }

        &.uitslag,
        &.uitslag:hover {
            background: rgba($color-echoblue, 0.25);
            // border: 2px dashed $color-echoblue;
            opacity: 0.3;
            cursor: default;
        }

        .fa-chevron-right.fa-w-10 {
            position: absolute;
            right: 20px;
            display: none;

            path {
                fill: $color-maroon;
            }
        }

        div:not(.btn) {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            align-items: center;
            color: $color-maroon;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            gap: $spacer;
        }
    }
}

a {
    color: $color-tangerine;
    transition: color 0.2s ease-in-out;

    &:hover {
        color: $color-maroon;
        transition: color 0.2s ease-in-out;
    }
}
</style>
