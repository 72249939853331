import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import AddCompany from '../views/AddCompany.vue'
import CompanyOverview from '../views/CompanyOverview.vue'
import AddLocation from '../views/AddLocation.vue'
import UpdateLocation from '../views/UpdateLocation.vue'
import Accreditatie from '../views/Accreditatie.vue'
import Uitslag from '../views/Uitslag.vue'
import Calendar from '../views/Calendar.vue'
import store from '../store/index.js'
import { api } from '@/api'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/overzicht',
        name: 'Afspraak-Overzicht',
        component: Calendar,
        meta: {
            requiresAuth: true,
            requiresPermission: 1,
            nav: true,
        },
    },
    {
        path: '/bedrijfs-overzicht',
        name: 'Bedrijfs-overzicht',
        component: CompanyOverview,
        meta: {
            requiresAuth: true,
            requiresPermission: 2,
            nav: true,
        },
    },
    {
        path: '/locatie-toevoegen/:id',
        name: 'Locatie-toevoegen',
        component: AddLocation,
        meta: {
            requiresAuth: true,
            requiresPermission: 2,
            nav: false,
        },
    },
    {
        path: '/locatie/:id/:locatie',
        name: 'Locatie-updaten',
        component: UpdateLocation,
        meta: {
            requiresAuth: true,
            requiresPermission: 2,
            nav: false,
        },
    },
    {
        path: '/bedrijf-toevoegen',
        name: 'Bedrijf-toevoegen',
        component: AddCompany,
        meta: {
            requiresAuth: true,
            requiresPermission: 2,
            nav: false,
        },
    },
    {
        path: '/accreditatie',
        name: 'Accreditatie',
        component: Accreditatie,
        meta: {
            requiresAuth: true,
            requiresPermission: 1,
            nav: true,
        },
    },
    {
        path: '/uitslag',
        name: 'Uitslag',
        component: Uitslag,
        meta: {
            requiresAuth: true,
            requiresPermission: 1,
            nav: true,
        },
    },
    {
        path: '*', // 404
        beforeEnter(to, from, next) {
            location.href = '/'
        },
    },
]

const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'is-active',
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    },
    routes,
})

router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem('access_token') == null || localStorage.getItem('permission') == null) {
            console.log('no access token or permission level')
            next('/')
        } else if (localStorage.getItem('access_token') && localStorage.getItem('permission')) {
            try {
                const response = await api.get('/checkAuth', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                        Permission: localStorage.getItem('permission'),
                    },
                })
                if (response.status === 200) {
                    if (
                        localStorage.getItem('permission') == 1 &&
                        localStorage.getItem('permission') != to.meta.requiresPermission
                    ) {
                        next('/')
                    } else {
                        next()
                    }
                } else {
                    next('/')
                }
            } catch (err) {
                if (err.response.status === 401) {
                    console.log('sorry you failed!')
                    localStorage.clear()
                    next('/')
                    return
                }
            }
        }
    } else {
        next()
    }
})

export default router
