<template>
    <div class="accreditatie">
        <Modal v-if="orderBook.length && holdUp">
            <h2>LET OP !</h2>

            <p>
                De persoon met <strong>OrderID: {{ orderBook[0].encryptedID }}</strong> is
                <strong class="error">POSITIEF</strong> getest.<br />
                Start hierbij het 'positief geteste persoon' protocol.
            </p>

            <Button icon="paper-plane" @click.native="sendMessage('pass')">Verstuur bericht</Button>
        </Modal>

        <div class="page__header">
            <h1>Uitslag</h1>
        </div>

        <div class="accreditatie__input">
            <Input
                v-model="searchModel"
                type="text"
                placeholder="Scan de test barcode (orderid)"
                :maxLength="12"
                :errorClass="error"
                :errorMessage="errorMessage"
                :autoFocus="true"
            />
            <Button icon="vial" @click.native="searchOrder(searchModel)">Zoek test</Button>
        </div>

        <hr class="seperator" />

        <div class="container">
            <Table
                :headings="[
                    { key: 'werkgever', label: 'Werkgever' },
                    { key: 'uan', label: 'UAN' },
                    { key: 'geboortedatum', label: 'Geboortedatum' },
                    { key: 'testdatum', label: 'Test moment' },
                    { key: 'email', label: 'Email' },
                    { key: 'telnr', label: 'Telefoon nr' },
                ]"
                :data="orderBook"
                defaultValue="---"
            >
            </Table>

            <div v-if="orderBook.length">
                <hr />
                <h4>Uitslag</h4>

                <div class="checkboxes" v-if="!disabled">
                    <label for="negatief">
                        <input type="radio" name="uitslag" id="negatief" value="0" v-model="uitslag" />
                        <span class="transparent success">Negatief</span>
                    </label>

                    <label for="inconclusive">
                        <input type="radio" name="uitslag" id="inconclusive" value="1" v-model="uitslag" />
                        <span class="transparent info">Inconclusive</span>
                    </label>

                    <label for="positief">
                        <input type="radio" name="uitslag" id="positief" value="2" v-model="uitslag" />
                        <span class="transparent error">Positief</span>
                    </label>

                    <Button
                        icon="paper-plane"
                        @click.native="sendMessage(0)"
                        v-if="orderBook[0].telnr && !orderBook[0].email"
                        :disabled="disabled"
                        >Verstuur SMS</Button
                    >
                    <Button
                        icon="paper-plane"
                        @click.native="sendMessage(0)"
                        v-else-if="orderBook[0].email && !orderBook[0].telnr"
                        :disabled="disabled"
                        >Verstuur Email</Button
                    >
                    <Button icon="paper-plane" @click.native="sendMessage(0)" v-else :disabled="disabled"
                        >Verstuur Email + SMS</Button
                    >
                </div>

                <span v-else>{{ orderBook[0].uitslag }}</span>
                <span class="success" v-if="success">{{ success }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import Modal from '@/components/Modal.vue'
import Input from '@/components/inputs/Textfield.vue'
import Button from '@/components/Button.vue'
import Table from '@/components/Table.vue'
import { api } from '@/api'

export default {
    name: 'Uitslag',

    components: {
        Modal,
        Input,
        Button,
        Table,
    },

    data() {
        return {
            searchModel: '',
            error: false,
            errorMessage: '',
            success: '',
            uitslag: null,
            holdUp: false,
            disabled: false,
            orderBook: [],
        }
    },

    methods: {
        async searchOrder(id) {
            this.error = false
            this.errorMessage = ''
            if (!id.length || id.length < 6 || id.length > 12) {
                this.errorMessage = 'Dit is geen geldig order id'
                this.error = true
                return
            }

            try {
                const response = await api.get('/fetchOrder', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                        Permission: localStorage.getItem('permission'),
                        hashedid: id,
                    },
                })

                const result = await response.data.data
                this.orderBook = [result]

                switch (this.orderBook[0].uitslag) {
                    case 0:
                        this.orderBook[0].uitslag = 'Negatief'
                        break
                    case 1:
                        this.orderBook[0].uitslag = 'Inconclusive'
                        break
                    case 2:
                        this.orderBook[0].uitslag = 'Positief'
                        break
                }

                if (result.uitslag !== null) this.disabled = true
            } catch (error) {
                this.error = true
                this.errorMessage = 'OrderID niet gevonden'
                return
            }
        },

        sendMessage(pass) {
            if (this.disabled) return

            if (this.uitslag == 2 && pass == 0) {
                this.holdUp = true
                this.$store.commit('SET_MODALSTATE', true)
            }
            if (this.uitslag == 2 && pass == 0) return

            this.holdUp = false
            this.updateUitslag()
        },

        async updateUitslag() {
            this.errorMessage = ''
            try {
                const response = await api.post(
                    '/updateUitslag',
                    {
                        uitslag: this.uitslag,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                            Permission: localStorage.getItem('permission'),
                            hashedid: this.searchModel,
                        },
                    }
                )
                const result = await response.data
                this.success = result
            } catch (error) {
                this.error = true
                this.errorMessage = 'OrderID niet gevonden'
                return
            }
        },
    },
}
</script>

<style lang="scss">
.accreditatie {
    &__input {
        max-width: 400px;
        width: 100%;
        margin-top: $spacer;
        display: grid;
        grid-template-columns: 1fr auto;
        gap: $spacer;
        align-items: flex-start;

        .input__wrapper {
            margin-top: 0;
        }
    }

    .container {
        background: $color-white;
        width: 100%;
        height: 100%;
        border-radius: 12px;
        padding: 24px;
        box-shadow: 0px 0px 10px rgba($color-maroon, 0.2);

        .input__rows {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 24px;
        }

        .checkboxes {
            max-width: 450px;
            width: 100%;
            display: grid;
            grid-template-columns: repeat(3, auto);
            grid-template-rows: 1fr 1fr;
            gap: $spacer;
            align-items: center;

            div {
                grid-column: 1/-1;
            }
        }
    }

    hr {
        margin: 48px auto;
    }
}

h1,
h2 {
    font-size: rem(24px);
    font-weight: $semi-bold;
    color: $color-burgundy;
    margin: 0 0 24px;
}

h2 {
    margin-bottom: 0;
}

strong.error {
    color: $color-error;
}

label {
    cursor: pointer;
}

span.error,
span.success,
span.info {
    display: inline-block !important;
    &.transparent {
        margin-left: 10px;
        background: transparent !important;
    }
}

span.error.transparent {
    color: $color-error !important;
}

span.success.transparent {
    color: $color-success !important;
}

span.info.transparent {
    color: $color-maroon !important;
}
</style>
