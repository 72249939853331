<template>
    <div :class="['input__wrapper', { small: small }]">
        <label :for="label" v-if="label">{{ label }}</label>
        <input
            :type="type"
            v-on="$listeners"
            :value="value"
            :placeholder="placeholder"
            @input="$emit('update', $event.target.value)"
            :class="[{ error: errorClass }, { small: small }]"
            :disabled="disabled"
            :maxlength="maxLength"
            ref="input"
        />
        <span class="error-message" v-if="errorMessage">{{ errorMessage }}</span>
    </div>
</template>

<script>
export default {
    props: {
        value: { type: [String, Number], default: '' },
        label: { type: String, default: '' },
        errorClass: { type: Boolean, default: false },
        errorMessage: { type: String, default: '' },
        placeholder: { type: String, default: '' },
        type: { type: String, default: 'text' },
        autoFocus: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        small: { type: Boolean, default: false },
        maxLength: { type: Number, default: null },
    },

    mounted() {
        if (this.autoFocus) this.focusInput()
    },

    methods: {
        focusInput() {
            this.$nextTick(function() {
                this.$refs.input.focus()
            })
        },
    },

    model: {
        prop: 'value',
        event: 'update',
    },
}
</script>

<style lang="scss" scoped>
.input__wrapper:not(.small) {
    margin-top: 24px;
}

input[type='text'],
input[type='password'],
input[type='email'],
input[type='number'],
select {
    width: 100%;
    height: 46px;
    outline: 0;
    padding: 0 10px;
    color: $color-burgundy;
    border: 1px solid $color-border;
    border-radius: 6px;
    box-shadow: 0px 4px 5px rgba(57, 9, 35, 0.05);
    transition: border-color 0.2s;
    font-size: 1rem;

    &.small {
        height: 30px;
    }

    &:focus {
        border-color: darken($color-border, 20%);
        transition: border-color 0.2s;
    }

    &.error {
        color: $color-error;
        border-color: $color-error;
        transition: border-color 0.2s;
    }
}

label {
    font-size: rem(14px);
    font-weight: $semi-bold;
    color: $color-maroon;
    display: block;
    margin-bottom: 6px;
}

span.error-message {
    color: $color-error;
    font-size: rem(12px);
    display: block;
    margin-top: 6px;
}
</style>
