<template>
    <div class="kalender">
        <!-- <Modal>{{ modalMessage }}</Modal> -->

        <div class="page__header">
            <h1>Afspraken overzicht</h1>
        </div>

        <hr class="seperator" />

        <div class="container">
            <div style="margin-bottom: 50px;">
                <v-date-picker v-model="date" :masks="masks">
                    <template v-slot="{ inputValue, inputEvents }">
                        <div class="input__wrapper">
                            <label for="date">Kies een dag</label>
                            <input id="date" class="input-text" :value="inputValue" v-on="inputEvents" />
                        </div>
                    </template>
                </v-date-picker>
            </div>

            <Table
                :headings="[
                    { key: 'werkgever', label: 'Werkgever' },
                    { key: 'uan', label: 'UAN' },
                    { key: 'encryptedID', label: 'ID' },
                    { key: 'geboortedatum', label: 'Geboortedatum' },
                    { key: 'email', label: 'Email' },
                    { key: 'telnr', label: 'Telefoon' },
                    { key: 'testdatum', label: 'Test moment' },
                ]"
                :data="appointments"
                :emptyMessage="error ? errorMessage : 'Er zijn geen afspraken gevonden'"
                defaultValue="---"
            >
            </Table>
        </div>
    </div>
</template>

<script>
import Modal from '@/components/Modal.vue'
import Input from '@/components/inputs/Textfield.vue'
import Button from '@/components/Button.vue'
import Table from '@/components/Table.vue'
import { api } from '@/api'

export default {
    name: 'Kalender',

    components: {
        Modal,
        Input,
        Button,
        Table,
    },

    data() {
        return {
            date: new Date(),
            masks: {
                input: 'DD/MM/YYYY',
            },
            appointments: [],
            error: false,
            errorMessage: '',
            success: false,
        }
    },

    mounted() {
        this.fetchAppointments(this.formatDate(new Date(this.date)))
    },

    methods: {
        padTo2Digits(num) {
            return num.toString()
        },

        formatDate(date) {
            return [this.padTo2Digits(date.getDate()), this.padTo2Digits(date.getMonth() + 1), date.getFullYear()].join(
                '/'
            )
        },

        async fetchAppointments(date) {
            this.error = ''

            try {
                const response = await api.get('/fetchAppointments', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                        Permission: localStorage.getItem('permission'),
                        formattedDate: date,
                    },
                })

                const result = await response.data

                this.appointments = result.appointments
            } catch (error) {
                this.appointments = []
                this.error = true
                this.errorMessage = 'Er zijn geen afspraken gevonden op deze dag'
            }
        },
    },

    watch: {
        date(e) {
            this.fetchAppointments(this.formatDate(new Date(e)))
        },
    },
}
</script>

<style lang="scss">
.kalender {
    &__input {
        max-width: 400px;
        width: 100%;
        margin-top: $spacer;
        display: grid;
        grid-template-columns: 1fr auto;
        gap: $spacer;
        align-items: flex-start;

        .input__wrapper {
            margin-top: 0;
        }
    }

    .container {
        background: $color-white;
        width: 100%;
        height: 100%;
        border-radius: 12px;
        padding: 24px;
        box-shadow: 0px 0px 10px rgba($color-maroon, 0.2);

        .input__rows {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 24px;
        }

        .span__util {
            transition: color 0.2s ease-in-out;

            &.success {
                color: $color-success;
            }

            &:hover {
                color: $color-tangerine;
                transition: color 0.2s ease-in-out;
            }
        }

        .barcode__wrapper {
            max-width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: $spacer;
            margin: $spacer * 2 auto 0;

            .barcode__image {
                max-width: 100%;
            }
        }
    }

    hr {
        margin: 48px auto;
    }
}

h1,
h2 {
    font-size: rem(24px);
    font-weight: $semi-bold;
    color: $color-burgundy;
    margin: 0 0 24px;
}
</style>

<style lang="scss" scoped>
.input-text {
    max-width: 400px;
    width: 100%;
    height: 46px;
    outline: 0;
    padding: 0 10px;
    color: $color-burgundy;
    border: 1px solid $color-border;
    border-radius: 6px;
    box-shadow: 0px 4px 5px rgba(57, 9, 35, 0.05);
    transition: border-color 0.2s;

    &:focus {
        border-color: darken($color-border, 20%);
        transition: border-color 0.2s;
    }

    &.error {
        color: $color-error;
        border-color: $color-error;
        transition: border-color 0.2s;
    }
}

label {
    font-size: rem(14px);
    font-weight: $semi-bold;
    color: $color-maroon;
    display: block;
    margin-bottom: 6px;
}
</style>
